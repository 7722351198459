// extracted by mini-css-extract-plugin
export var featureSlide = "features-module--featureSlide--1bK8B";
export var featureDescription = "features-module--featureDescription--1wO1S";
export var featureHeading = "features-module--featureHeading--3Nd5q";
export var featurePicture = "features-module--featurePicture--2lMb0";
export var one = "features-module--one--1ZUO5";
export var two = "features-module--two--2BeEh";
export var three = "features-module--three--21cZs";
export var four = "features-module--four--2ETY3";
export var five = "features-module--five--1KSWu";
export var six = "features-module--six--2xQCQ";
export var seven = "features-module--seven--1yloK";
export var eight = "features-module--eight--2iMbX";
export var nine = "features-module--nine--3v5zc";
export var ten = "features-module--ten--2EADM";
export var eleven = "features-module--eleven--3pj6T";